<template>
  <div
    style="position: relative; height: calc(100vh - 50px); overflow-y: scroll"
  >
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />

    <div class="activity_create_back" @click="handleBack">
      <el-button type="text">
        <i class="el-icon-arrow-left"></i>返回订单列表</el-button
      >
    </div>
    <div
      class="activity_create_bg"
      v-if="playRowDetailData.bizOrder.orderStatus == '支付审核通过，已支付'"
    >
      <h4>订单状态：{{ playRowDetailData.bizOrder.orderStatus }}</h4>
      <p>订单号：{{ playRowDetailData.bizOrder.payOrderId }}</p>
      <!-- <p>最晚确认时间：{{ timeRemaining > 0 ? timeRemaining : "已超时" }}</p> -->
      <div style="display: flex">
        <el-button type="primary" @click="handleClick('success')"
          >确认订单</el-button
        >
        <div class="activity_examine_reason">
          <el-input
            placeholder="请输入取消原因"
            v-model="failReason"
          ></el-input>
          <el-button :disabled="failReason == ''" @click="handleClick('fail')"
            >取消订单</el-button
          >
        </div>
      </div>
    </div>

    <div
      class="activity_create_bg"
      v-if="playRowDetailData.bizOrder.orderStatus == '已确认'"
    >
      <h4>
        订单状态：已确认（手机号：{{
          playRowDetailData.bizOrder.contactPhone
        }}）
      </h4>
      <p>订单号：{{ playRowDetailData.bizOrder.payOrderId }}</p>
      <div style="display: flex">
        <div class="activity_examine_reason" style="margin-left: 0">
          <el-input
            placeholder="请输入取消原因"
            v-model="failReason"
          ></el-input>
          <el-button :disabled="failReason == ''" @click="handleClick('fail')"
            >取消订单</el-button
          >
        </div>
      </div>
    </div>

    <div
      class="activity_create_bg"
      v-if="playRowDetailData.bizOrder.orderStatus == '未支付'"
    >
      <h4>
        订单状态：{{ playRowDetailData.bizOrder.orderStatus
        }}<span v-if="playRowDetailData.bizOrder.orderStatus == '未支付'"
          >（{{
            timeRemaining > 0 ? `倒计时${timeRemaining}` : "已超时"
          }}）</span
        >
      </h4>
      <p>订单号：{{ playRowDetailData.bizOrder.payOrderId }}</p>
    </div>

    <div
      class="activity_create_bg"
      v-if="playRowDetailData.bizOrder.orderStatus == '平台取消'"
    >
      <h4>订单状态：{{ playRowDetailData.bizOrder.orderStatus }}</h4>
      <p>订单号：{{ playRowDetailData.bizOrder.payOrderId }}</p>
    </div>

    <div
      class="activity_create_bg"
      v-if="playRowDetailData.bizOrder.orderStatus == '用户取消'"
    >
      <h4>订单状态：已退订</h4>
      <p>退订原因：{{ playRowDetailData.bizOrder.cancelType }}</p>
    </div>

    <div
      v-if="
        playRowDetailData.bizOrder.orderStatus == '支付审核通过，已支付' ||
        playRowDetailData.bizOrder.orderStatus == '平台取消'
      "
    >
      <div class="activity_create_bg">
        <h4>订单信息</h4>
        <p>订单号：{{ playRowDetailData.bizOrder.payOrderId }}</p>
        <p v-if="playRowDetailData.bizOrder.orderStatus == '平台取消'">
          取消原因：{{ playRowDetailData.bizOrder.cancelReason }}
        </p>
        <p
          v-if="
            playRowDetailData.bizOrder.orderStatus == '支付审核通过，已支付'
          "
        >
          下单时间：{{ playRowDetailData.bizOrder.createTime }}
        </p>
        <p
          v-if="
            playRowDetailData.bizOrder.orderStatus == '支付审核通过，已支付'
          "
        >
          付款时间：{{ playRowDetailData.payOrder.payTime }}
        </p>

        <el-table :data="tableData" border>
          <el-table-column label="商品主图" align="center" width="200">
            <template slot-scope="scope">
              <el-image
                style="height: 120px"
                :src="scope.row.sightseeingDTO.imageList[0]"
                :preview-src-list="scope.row.sightseeingDTO.imageList"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="sightseeingDTO.goodsName"
            label="商品名称"
            width="200"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="bizOrderExt.playTime"
            label="游玩日期"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column label="规格" align="center" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.bizOrderExt.skuAttr }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="bizOrderExt.singlePrice"
            label="单价"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="bizOrderExt.goodsNum"
            label="数量"
            align="center"
          >
          </el-table-column>
          <el-table-column label="实收款" align="center" width="160">
            <template slot-scope="{ row }">
              <p v-if="row.payOrder.payType == 1">${{ row.payOrder.amount }}</p>
              <p v-else-if="row.payOrder.payType == 10">
                {{ row.payOrder.p95Amount }}<br />
                CPLE积分抵扣：-${{ row.payOrder.p05Amount }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="payOrder.orderId"
            label="订单号"
            align="center"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="bizOrder.createTime"
            label="下单时间"
            width="180"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>

      <div class="activity_create_bg">
        <h4>游玩信息</h4>
        <p>游玩日期：{{ playRowDetailData.bizOrderExt.playTime }}</p>
        <p>规格：{{ playRowDetailData.bizOrderExt.skuAttr }}</p>
        <p>数量：{{ playRowDetailData.bizOrderExt.goodsNum }}</p>
      </div>

      <div class="activity_create_bg">
        <h4>预定人信息</h4>
        <p>手机号：{{ playRowDetailData.bizOrder.contactPhone }}</p>
        <p>邮箱：{{ playRowDetailData.bizOrder.contactEmail }}</p>
      </div>

      <div class="activity_create_bg">
        <h4>护照信息</h4>
        <div
          v-for="(item, index) in playRowDetailData.bizOrder.playerList"
          :key="index"
        >
          <div>
            <p style="width: 350px">
              游客{{ index + 1 }}：{{ item.contactLastname
              }}{{ item.contactFirstname }}
            </p>
            <p>
              护照ID：
              {{ item.passportId }}
            </p>
            <p>
              护照有效期：
              {{ item.passportEnd }}
            </p>
          </div>
        </div>
      </div>

      <div class="activity_create_bg">
        <h4>其他信息</h4>
        <p>
          单价：$ {{ playRowDetailData.bizOrderExt.singlePrice }}（{{
            playRowDetailData.bizOrderExt.userGrade
          }}）
        </p>
        <p>数量：{{ playRowDetailData.bizOrderExt.goodsNum }}</p>
        <p v-if="playRowDetailData.payOrder.payType == 1">
          实收款：$ {{ playRowDetailData.payOrder.amount }}
        </p>
        <p v-else-if="playRowDetailData.payOrder.payType == 10">
          实收款：$
          {{ playRowDetailData.payOrder.p95Amount }} (CPLE积分抵扣：<span
            >-${{ playRowDetailData.payOrder.p05Amount }}</span
          >)
        </p>
        <p>订单编号：{{ playRowDetailData.bizOrder.payOrderId }}</p>
        <p>下单时间：{{ playRowDetailData.bizOrder.createTime }}</p>
        <p>付款时间：{{ playRowDetailData.payOrder.payTime }}</p>
        <p>支付方式：{{ playRowDetailData.payOrder.payType }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import { format_time_date } from "@/util/funBusiness";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { hideLoading, showLoading } from "@/util/loading";

export default {
  components: { Breadcrumb },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行世界",
        },
        {
          name: "活动管理",
        },
        {
          name: "订单列表",
        },
        {
          name: "查看详情",
        },
      ], // 面包屑数据

      failReason: "",

      tableData: [],
      playRowDetailData: {},
      flag: false,
      time: "",
      timeRemaining: "",
    };
  },

  mounted() {
    this.playRowDetailData = JSON.parse(
      localStorage.getItem("playRowDetailData")
    );
    console.log(this.playRowDetailData);
    this.changeItem();

    this.tableData.push(this.playRowDetailData);

    let time = setInterval(() => {
      if (this.flag == true) {
        clearInterval(time);
      }
      this.timeDown();
    }, 500);
  },

  props: { endTime: { type: String } },

  methods: {
    handleBack() {
      this.$router.push("/worldPlayOrderList");
      localStorage.removeItem("playRowDetailData");
    },

    changeItem() {
      this.playRowDetailData.payOrder.payTime = format_time_date(
        this.playRowDetailData.payOrder.payTime
      );

      if (this.playRowDetailData.payOrder.payType == 1) {
        this.playRowDetailData.payOrder.payType = "余额账户";
      } else if (this.playRowDetailData.payOrder.payType == 2) {
        this.playRowDetailData.payOrder.payType = "CPLE积分账户";
      } else if (this.playRowDetailData.payOrder.payType == 3) {
        this.playRowDetailData.payOrder.payType = "支付宝账户";
      } else if (this.playRowDetailData.payOrder.payType == 4) {
        this.playRowDetailData.payOrder.payType = "微信账户";
      } else if (this.playRowDetailData.payOrder.payType == 5) {
        this.playRowDetailData.payOrder.payType = "京东账户";
      } else if (this.playRowDetailData.payOrder.payType == 6) {
        this.playRowDetailData.payOrder.payType = "转出额度";
      } else if (this.playRowDetailData.payOrder.payType == 7) {
        this.playRowDetailData.payOrder.payType = "USDT";
      } else if (this.playRowDetailData.payOrder.payType == 8) {
        this.playRowDetailData.payOrder.payType = "链上CPLE TOKEN";
      } else if (this.playRowDetailData.payOrder.payType == 9) {
        this.playRowDetailData.payOrder.payType = "数字人民币";
      } else if (this.playRowDetailData.payOrder.payType == 10) {
        this.playRowDetailData.payOrder.payType = "95%余额+5%cple";
      }

      if (this.playRowDetailData.sightseeingDTO.cancelRule == 0) {
        this.playRowDetailData.sightseeingDTO.cancelRule = "不可退订";
      } else if (this.playRowDetailData.sightseeingDTO.cancelRule == 1) {
        this.playRowDetailData.sightseeingDTO.cancelRule = "提前1天退订";
      } else if (this.playRowDetailData.sightseeingDTO.cancelRule == 2) {
        this.playRowDetailData.sightseeingDTO.cancelRule = "提前3天退订";
      } else if (this.playRowDetailData.sightseeingDTO.cancelRule == 3) {
        this.playRowDetailData.sightseeingDTO.cancelRule = "提前7天退订";
      } else if (this.playRowDetailData.sightseeingDTO.cancelRule == 4) {
        this.playRowDetailData.sightseeingDTO.cancelRule = "提前14天退订";
      }

      if (this.playRowDetailData.bizOrderExt.userGrade == 1) {
        this.playRowDetailData.bizOrderExt.userGrade = "A级专属价";
      } else if (this.playRowDetailData.bizOrderExt.userGrade == 2) {
        this.playRowDetailData.bizOrderExt.userGrade = "B级专属价";
      } else if (this.playRowDetailData.bizOrderExt.userGrade == 3) {
        this.playRowDetailData.bizOrderExt.userGrade = "C级专属价";
      } else if (this.playRowDetailData.bizOrderExt.userGrade == 4) {
        this.playRowDetailData.bizOrderExt.userGrade = "D级专属价";
      } else if (this.playRowDetailData.bizOrderExt.userGrade == 0) {
        this.playRowDetailData.bizOrderExt.userGrade = "无专属价";
      }

      if (this.playRowDetailData.bizOrder.cancelType == 0) {
        this.playRowDetailData.bizOrder.cancelType =
          this.playRowDetailData.bizOrder.cancelReason;
      } else if (this.playRowDetailData.bizOrder.cancelType == 1) {
        this.playRowDetailData.bizOrder.cancelType = "行程取消";
      } else if (this.playRowDetailData.bizOrder.cancelType == 2) {
        this.playRowDetailData.bizOrder.cancelType = "信息填错";
      } else if (this.playRowDetailData.bizOrder.cancelType == 3) {
        this.playRowDetailData.bizOrder.cancelType = "预定时间太长";
      } else if (this.playRowDetailData.bizOrder.cancelType == 4) {
        this.playRowDetailData.bizOrder.cancelType = "质量差";
      } else if (this.playRowDetailData.bizOrder.cancelType == 5) {
        this.playRowDetailData.bizOrder.cancelType = "其他";
      }
    },

    handleClick(type) {
      this.$confirm("您确定要执行该操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (type == "success") {
          showLoading();
          const opt = {
            url: reqUrl.worldConfirmOrder,
            method: "POST",
            params: JSON.stringify({
              orderId: this.playRowDetailData.bizOrder.orderId,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                hideLoading();
                this.$router.push("/worldPlayOrderList");
                this.$message.success("操作成功");
                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
            },
          };
          request(opt);
        } else if (type == "fail") {
          showLoading();
          const opt = {
            url: reqUrl.worldCancelOrderByAuditor,
            method: "POST",
            params: JSON.stringify({
              orderId: this.playRowDetailData.bizOrder.orderId,
              cancelReason: this.failReason,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                hideLoading();
                this.$router.push("/worldPlayOrderList");
                this.$message.success("操作成功");
                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
            },
          };
          request(opt);
        }
      });
    },

    timeDown() {
      // 设置倒计时结束时间
      var endTime = new Date(
        format_time_date(this.playRowDetailData.payOrder.payTimeout)
      );

      // 获取当前时间
      var currentTime = new Date();

      // 计算剩余时间
      var timeRemaining = endTime - currentTime;

      // 将剩余时间转换为天、小时、分钟和秒
      var days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      var seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.timeRemaining =
        days + "天 " + hours + "小时 " + minutes + "分钟 " + seconds + "秒";
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../index.scss";

.el-button {
  margin-top: 10px;
  border-radius: 20px;
}
</style>
